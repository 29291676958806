<template>
	<div class="content">
		<img class="banner" src="../../assets/img/case/banner@2x.png"/>
		<div class="bg-fff">
			<div class="case-list border-btm" v-for="(item, num) in list" :key="num" @click="goDetails(num + 1)">
				<img :src="item.img"/>
				<div>
					<strong>{{item.name}}</strong>
					<span class="over-hidden">{{item.miaoshu}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				title: '展示案例',
				list: [
					{
						img: require('../../assets/img/case/01.png'),
						name: '新企业申请400电话有哪些注意事项',
						miaoshu: '越来越多的企业会在开业之初便选择申请400电话。'
					},
					{
						img: require('../../assets/img/case/02.png'),
						name: '400电话，解决企业的零售难题！',
						miaoshu: '当下时兴的直播销售模式，以观看更直观、价格更低廉、购买更放心的优势深受消费者喜欢。'
					},
					{
						img: require('../../assets/img/case/04.jpg'),
						name: '开通400电话，能帮企业成功避开哪些坑？',
						miaoshu: '开通400电话，能帮企业成功避开哪些坑呢？企业一路走来多少会有一些事情，有的可以避免，有的却防不胜防。'
					},
					{
						img: require('../../assets/img/case/05.jpg'),
						name: '企业开通400电话，到底有多省钱？',
						miaoshu: '大家都在开通400电话，那么企业开通400电话，到底有多省钱？我们都知道400电话就是企业不可多得的“对”的电话服务合作伙伴。'
					}
				]
			}
		},
		methods: {
			// 购买
			goDetails(number) {
				this.$router.push({name: 'CaseDetails', query: { number }})
			}
		}
	}
</script>

<style lang="less" scoped>
	.content {
		height: 100%;
	}

	.banner {
		display: block;
		height: 241px;
		margin: 0 auto;
	}

	.bg-fff {
		background: #fff;
		margin-top: 16px;
	}

	.case-list:last-child {
		border-bottom: 0;
	}

	.case-list {
		width: 686px;
		padding: 40px 0;
		margin: 0 auto;
		.flex;
		.align-items-center;


		img {
			display: block;
			width: 208px;
			height: 138px;
			background-color: #ffffff;
			border-radius: 10px;
			border: solid 1px #e1e3e6;
			margin-right: 40px;
		}

		strong, span {
			display: block;
		}

		strong {
			font-size: @font-size4;
			color: @color-191b21;
		}

		.over-hidden {
			width: 436px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: @font-size2;
			margin-top: 24px;
		}
	}
</style>
